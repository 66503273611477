import {
    Box,
    chakra,
    Flex,
    ListItem,
    UnorderedList,
} from '@chakra-ui/react';

const HomePage = () => {
    return (
        <Box
            bg="#edf3f8"
            p={50}
        >
            <Box width={{ base: 'full', sm: 'lg', lg: 'xl' }} margin={'auto'}>
                <chakra.h3
                    fontWeight={'bold'}
                    fontSize={32}
                    color={'blue.400'}>
                    簡介
                </chakra.h3>
                <chakra.h1
                    py={5}
                    fontSize={40}
                    fontFamily={'Work Sans'}
                    fontWeight={'bold'}
                    color={'gray.700'}>
                    台大金融科技研究中心 FinTech@NTU
                </chakra.h1>
            </Box>
            <Flex
                boxShadow={'lg'}
                alignItems={'center'}
                width={'100%'}
                rounded={'xl'}
                p={10}
                position={'relative'}
                bg={'white'}
                _after={{
                    content: '""',
                    position: 'absolute',
                    height: '50px',
                    width: '50px',
                    left: '25px',
                    top: '-20px',
                    backgroundSize: 'cover',
                    backgroundImage: "url('/quotation_marks.png')",
                }}>
                <Box
                    textAlign={'left'}>
                    <chakra.p
                        fontFamily={'Inter'}
                        fontWeight={'medium'}
                        fontSize={'20px'}
                        pb={4}
                    >
                        金融科技是「資訊科學、財務學與社會科學」的跨領域研究，然而，隨著資訊科技日益發達，大量資訊的蒐集、傳遞、保管與分析變得更加容易。這樣的發展會使得金融交易的成本大幅下降，也使金融交易雙方的資訊不對稱問題更容易解決。
                        <br /><br />
                        <UnorderedList>
                            <ListItem>在交易成本部分，網路與電腦運算速度的提高以及資訊儲存設備價格下降使金融交易與紀錄的成本大幅下降，生物辨識技術（如語音、指紋、人臉、虹膜、靜脈等）的發展降低了杜絕盜用問題所需的交易成本，區塊鏈技術的發展則降低了因防止資料竄改問題而發生的交易成本。</ListItem>
                            <ListItem>在借款人違約風險的辨識上，由於資料蒐集與處理成本降低，故除了傳統徵信資料（借款人是否有違約紀錄，以及其職業、年齡、收入等個人資料）之外，貸方也可以透過其他資料來源來判斷借款人的違約可能性。這些資料來源廣泛，包含網路購物平台業者所蒐集的消費者消費與付款資訊、透過網路爬蟲所蒐集到借款人在社群網路中的活動與人際網絡資訊、由借款人手機活動所蒐集到的資訊等。</ListItem>
                            <ListItem>在保險方面，保險公司可以透過穿戴裝置蒐集投保人健康資訊，也可以透過在車輛上安裝汽車行車診斷系統蒐集開車里程與駕駛人駕駛習慣資訊。這些資訊可降低保險公司與投保人之間的資訊不對稱，讓保險公司設計對雙方都更為有利的保單。</ListItem>
                            <ListItem>在傳統的商業決策分析方面，人工智慧與機器學習的大量使用，使得資料的分析更加有效與直接，對於顧客的精準行銷，以及顧客對於商品的精準搜尋與推薦，是每個商家的必爭之地。對談機器人的產生，更是讓顧客與商家的互動達到全天24小時不間斷。</ListItem>
                        </UnorderedList>
                        <br />
                        近年來這些科技的快速發展與破壞式創新，帶來了顛覆性變革，也重塑了全球的商業市場與金融生態。新興的創新型態包含了主打共享經濟的Uber、Airbnb，第三方支付、P2P網路借貸、群眾募資、大數據應用、區塊鏈之延伸應用、以及更多金融業在AI上的不同應用，例如理財機器人等新興的商業行為、交易型態，都對監管機構與現有的監管架構形成新的挑戰。
                        <br /><br />
                        臺灣大學擁有最好的資訊電機、金融管理與法律人才，因此成立「金融科技研究中心」乃眾望所歸。本中心期望透過跨領域產學合作，運用資訊科技創新服務、創新商業模式的應用，來推動金融科技業，以提升我國競爭力，並研發符合市場之跨領域金融應用，包括：「綠能科技、物聯網、生物科技、精密機械、國防產業」，配合人工智慧、資訊技術及金融科技法規管理與制定，促進數據研究及安全等面向的深化落實，強化我國金融產業發展、建立數位化容服務與商業模式，並運用中小企業信用保證基金之保證機制，積極協助我國中小企業轉型。此外，目前我國人口結構邁向高齡化，民眾退休安養與照護需求將快速成長，衍生之年金改革、長照及公共基礎建設護資源不足及工作人口減少等議題均亟待解決，本中心冀望透過資金的引導配置與金融專業服務，做到以金融支持產業發展，帶動產業創新轉型、建立高齡化社會保障機制、支持公共基礎建設等政策之金融需求，將能翻轉經濟，擴大國內金融市場規模，提升金融產業國際競爭力。
                    </chakra.p>
                </Box>
            </Flex>
        </Box>
    )
}
export default HomePage;