const REPORTS = [
    {
        date: "2023-03-02",
        title: "人臉活體檢測",
        time: "20230302",
        pic: "FAS示意圖.jpg",
        file: "人臉活體檢測金融科技報告.pdf",
        pic_ref: "https://www.ipi-singapore.org/tech-offers/174805/face-anti-spoofing-technology.html",
    },
]

export { REPORTS }