const CHIEF_PREV = [
    {
        name_ch: '張智星',
        name_en: 'Jyh-Shing Roger Jang',
        title: '臺灣大學資訊工程學系/資訊網路與多媒體研究所 教授、玉山金控 科技長',
        email: 'jang@csie.ntu.edu.tw',
        expertise: "精準行銷/搜尋/推薦、金融科技之AI應用、醫療大數據分析、音樂分析/檢索、語音辨識/評分、多媒體分析/檢索",
        website: "http://mirlab.org/jang",
        bio:
            '張智星教授於 1992 年取得加州大學柏克萊分校的電機電腦博士，1993 年的 ANFIS 論文在 Google Scholar 被引用次數超過一萬次。張教授於 1993 年加入美國 MathWorks 公司，開發與 MATLAB 共用的模糊邏輯工具箱。1995 年回台任教後，專注於機器學習的各項應用，包含語音、音樂、文件、影像辨識等領域。他曾經擔任 2014 及 2017 年 ISMIR 的大會主席及共同主席，他的團隊也在歷年 MIREX 國際音樂檢索評比中的數個項目拿下第一名的佳績。目前張教授執行多項與金融科技相關之計畫，包含精準行銷、搜尋與推薦，並擔任玉山金控科技長，以及工研院資通所的顧問。',
        avatar:
            '/jyh_shing_jang.jpg',
    },
    {
        name_ch: '陳祝嵩',
        name_en: 'Chu-Song Chen',
        title: '台灣大學資訊工程學系／資訊網路與多媒體研究所 教授',
        email: 'chusong@csie.ntu.edu.tw',
        expertise: "人工智慧、深度學習、電腦視覺、大數據分析、機器學習、多媒體處理、醫學影像",
        website: " https://imp.iis.sinica.edu.tw/",
        bio:
            '',
        avatar:
            '/chu_song_chen.jpg',
    },
]

const HEAD_MEMBERS = [
    {
        name_ch: '陳祝嵩',
        name_en: 'Chu-Song Chen',
        title: '台灣大學資訊工程學系／資訊網路與多媒體研究所 教授',
        email: 'chusong@csie.ntu.edu.tw',
        expertise: "人工智慧、深度學習、電腦視覺、大數據分析、機器學習、多媒體處理、醫學影像",
        website: " https://imp.iis.sinica.edu.tw/",
        bio:
            '',
        avatar:
            '/chu_song_chen.jpg',
    },
    {
        name_ch: '陳業寧',
        name_en: 'Yehning Chen',
        title: '臺灣大學財務金融學系暨研究所 教授',
        email: 'ynchen@ntu.edu.tw',
        expertise: "金融機構、財務賽局、公司理財",
        website: "http://www.management.ntu.edu.tw/Fin/faculty/teacher/sn/36",
        bio:
            '陳業寧教授為國立臺灣大學工商管理系學士、美國UCLA管理博士。1994年起任職於國立臺灣大學，曾擔任臺大財務金融學系主任與臺大管理學院副院長。其曾獲得兩次臺大教學傑出獎以及七次臺大教學優良獎，以及「台新金控臺灣大學管理學院林煜宗管理研究學者獎」。陳教授目前擔任TSSCI期刊「財務金融學刊」主編以及臺灣財務金融學會理事，也曾經擔任中央存款保險公司董事以及財團法人金融消費評議中心之董事與監察人。陳教授主要研究領域為金融機構，包含擠兌、銀行資訊揭露、銀行自有資本管制、銀行流動性創造、中小企業信用風險預測等，研究成果曾發表於Journal of Political Economy、Journal of Financial Intermediation、Journal of Money, Credit and Banking、Financial Management、Journal of International Money and Finance與Information Systems Research等國際期刊。',
        avatar:
            '/yeh_ning_chen.png',
    },
    {
        name_ch: '邵慶平',
        name_en: 'Ching-Ping Shao',
        title: '臺灣大學法律學院 教授',
        email: 'cpshao@ntu.edu.tw',
        expertise: "金融法、商法",
        website: "http://www.law.ntu.edu.tw/index.php/認識本院/本院師資/item/228-邵慶平",
        bio:
            "邵慶平教授是臺灣大學法學士、法學碩士，美國柏克萊加州大學法學碩士（LLM）、法學博士（SJD），曾任國際通商法律事務所律師、中正大學法律學系教授。自2011年起任教於臺灣大學法律學院，曾兼任企業暨金融法制研究中心主任、NTU Law Review主編及兼任副院長一職。邵教授曾獲得中正大學青年學者獎、科技部優秀年輕學者研究計畫、臺灣大學教學傑出獎。主要研究領域包括公司法、證券交易法、金融法。我國公司法「閉鎖性股份有限公司專節」的立法，邵教授是主要的倡議者與專節條文的起草者。",
        avatar:
            '/ching_ping_shao.jpg',
    },
];

const FINTECH_MEMBERS = [
    {
        name_ch: '薛智文',
        name_en: 'Chih-Wen Steven Hsueh',
        title: '國立臺灣大學資訊工程學系/資訊網路與多媒體研究所 副教授',
        email: 'cwhsueh@csie.ntu.edu.tw',
        expertise: "即時系統、嵌入式系統、即時作業系統、智慧型系統",
        website: "http://www.csie.ntu.edu.tw/~cwhsueh",
        bio:
            'Chih-Wen (Steven) Hsueh is an associate professor in the Graduate Institute of Networking and Multimedia and Department of Computer Science and Information Engineering at the National Taiwan University (NTUCSIE), R.O.C., since 2013, August, joined as an assistant professor in 2006 March. His research interests include real-time systems, embedded systems, and operating systems. He received the BS degree from NTUCSIE in 1989, June. After two years of military service, he worked for one year as a research assistant in the Institute of Information Science at the Academia Sinica, R.O.C. (ASIIS). He received his MS degree in Computer Science from the University of Southern California in 1994, June, and PhD in Information and Computer Science from the University of California at Irvine in 1997, December. Then, he worked for several startups in Bay Area. He joined as an assistant professor in the Department of Computer Science and Information Engineering at the National Chung Cheng University, R.O.C., in 1999, August and an associate research engineer in ASIIS, in 2005, August.',
        avatar:
            '/chih_wen_hsueh.jpg',
    },
    {
        name_ch: '張智星',
        name_en: 'Jyh-Shing Roger Jang',
        title: '臺灣大學資訊工程學系/資訊網路與多媒體研究所 教授、玉山金控 科技長',
        email: 'jang@csie.ntu.edu.tw',
        expertise: "精準行銷/搜尋/推薦、金融科技之AI應用、醫療大數據分析、音樂分析/檢索、語音辨識/評分、多媒體分析/檢索",
        website: "http://mirlab.org/jang",
        bio:
            '張智星教授於 1992 年取得加州大學柏克萊分校的電機電腦博士，1993 年的 ANFIS 論文在 Google Scholar 被引用次數超過一萬次。張教授於 1993 年加入美國 MathWorks 公司，開發與 MATLAB 共用的模糊邏輯工具箱。1995 年回台任教後，專注於機器學習的各項應用，包含語音、音樂、文件、影像辨識等領域。他曾經擔任 2014 及 2017 年 ISMIR 的大會主席及共同主席，他的團隊也在歷年 MIREX 國際音樂檢索評比中的數個項目拿下第一名的佳績。目前張教授執行多項與金融科技相關之計畫，包含精準行銷、搜尋與推薦，並擔任玉山金控科技長，以及工研院資通所的顧問。',
        avatar:
            '/jyh_shing_jang.jpg',
    },
    {
        name_ch: '陳君明',
        name_en: 'Jun-Ming Chen',
        title: '國立臺灣大學數學系 助理教授',
        email: 'mchen.ntu@gmail.com',
        expertise: "密碼學、計算代數",
        website: "http://www.math.ntu.edu.tw/~jmchen",
        bio:
            '國立臺灣大學數學系學士與碩士、美國 Purdue University 數學博士。任職於臺大數學系、以嵌入式系統安全為核心業務的「銓安智慧科技」。臺大「教學傑出獎」得主，平均每兩百位臺大教師僅一位獲獎。在臺大教授的課程包括：密碼學導論、橢圓曲線密碼學、破密學專題、後量子密碼學、金融科技導論、電資學院微積分、通識課程數學與文明。臺北市臺大校友會高爾夫球隊發起人之一、該球隊現任總幹事。中華民國橋藝協會理事、代表臺灣參加2016橋牌世界盃之國家代表隊隊長。',
        avatar:
            '/jun_ming_chen.jpg',
    },
    {
        name_ch: '林守德',
        name_en: 'Shou-De Lin',
        title: '國立臺灣大學資訊工程學系 教授',
        email: 'sdlin@csie.ntu.edu.tw',
        expertise: "機器發明、知識發現、自然語言處理、人工智慧、社群網路分析",
        website: "https://www.csie.ntu.edu.tw/~sdlin/",
        bio:
            '林守德教授擁有台灣大學電機工程學士、密西根大學電機碩士、南加州大學計算語言學碩士學位和計算機科學博士學位，目前為國立臺灣大學資訊工程學系的教授，他創立台大的機器發明與社群網路探勘實驗室。在加入台大之前，他是羅沙拉摩斯國家實驗室的博士後研究員。林教授的研究包括機器學習和資料探勘、社群網絡分析和自然語言處理等領域。他的國際學術成就包括 2003 年 IEEE 網路智能會議最佳論文獎、2007 年 Google 研究獎、三度獲得微軟研究獎、IBM 研究獎助，2010 年及 2014 TAAI 優秀論文獎、2011 年 ASONAM 最佳論文獎，以及連續 5 年獲得美國空軍 AFOSR / AOARD 研究獎。他亦是 ACM KDD Cup 的 All Time Leader，領導或共同領導台大團隊贏得 5 次冠軍，今年也帶領研究團隊贏得 2016 年 WSDM Cup。他也曾獲得科技部吳大猷先生紀念獎，傑出人才基金會年輕學者研究獎，曾擔任 SIGKDD 的 senior program chair 及 ACL 的 area chair，目前是國際社群網絡探勘期刊、資訊科學與工程期刊和中文計算語言學期刊的副主編，也是科學人的「網路不打烊」單元作家之一。',
        avatar:
            '/shou_de_lin.jpg',
    },
    {
        name_ch: '蕭旭君',
        name_en: 'Hsu-Chun Hsiao',
        title: '國立臺灣大學資訊工程學系 副教授',
        email: 'hchsiao@csie.ntu.edu.tw',
        expertise: "資訊安全、自動化軟體漏洞分析、隱私防護",
        website: "https://www.csie.ntu.edu.tw/~hchsiao",
        bio:
            "Dr. Hsu-Chun Hsiao is an Associate Professor in the Department of Computer Science and Information Engineering, and the Graduate Institute of Networking and Multimedia at National Taiwan University. She also holds an adjunct researcher position in the Center of Information Technology and Innovation at Academia Sinica. Dr. Hsiao completed her B.S. (2006) and M.S. (2008) at National Taiwan University and Ph.D. at Carnegie Mellon University (2014). Dr. Hsiao's research interests lie in the field of network and systems security, and her recent work focuses on DDoS defense, IoT security, and automated vulnerability discovery. She is a recipient of the MOST Young Scholar Fellowship (2018-2023), K. T. Li Young Researcher Award by IICM and ACM Taipei/Taiwan Chapter (2018), and the NTU Excellent Teaching Award (2016, 2017). She runs the Network Security Lab (https://nslab.csie.ntu.edu.tw) at NTU and the lab's security competition team, Balsn, has won several  competitions and ranked highly internationally (https://balsn.tw).",
        avatar:
            '/hsu_chun_hsiao.jpg',
    },
    {
        name_ch: '陳縕儂',
        name_en: 'Yun-Nung Chen',
        title: '國立臺灣大學資訊工程學系 副教授',
        email: 'yvchen@csie.ntu.edu.tw',
        expertise: "語言理解、對話系統、機器智慧、自然語言處理",
        website: "https://www.csie.ntu.edu.tw/~yvchen",
        bio:
            "陳縕儂 (Yun-Nung Vivian Chen) 現為國立台灣大學資訊工程學系副教授，2015 年於美國卡內基美隆大學之電腦科學院獲頒博士學位，而後加入美國微軟研究院之深度學習科技中心任研究職務。研究專長包含對話系統、語言理解、以及深度學習，曾獲 Google Faculty Award、傑出人才年輕學者創新獎、科技部年輕學者獎及多次最佳論文獎。欲瞭解陳博士的研究及經歷，請至她的個人網頁 http://vivianchen.idv.tw。",
        avatar:
            '/yun_nung_chen.png',
    },
];

const FINBUSS_MEMBERS = [
    {
        name_ch: '張森林',
        name_en: 'San-Lin Chung',
        title: '國立臺灣大學財務金融學系暨研究所 教授',
        email: 'chungsl@ntu.edu.tw',
        expertise: "資產配置、財務工程、財務管理、隨機利率模型",
        website: "http://www.fin.ntu.edu.tw/~slchung",
        bio:
            '張森林為台灣大學電機學士、台灣大學商學研究所碩士及英國蘭開斯特大學財務博士，現任職國立台灣大學財務金融學系特聘教授及證劵期貨暨財務工程研究中心主任，並擔任Journal of Futures Markets編輯委員、「臺大管理論叢」及「財務金融學刊」副總編輯、「管理學報」、「證券市場發展季刊」及「期貨與選擇權學刊」編輯委員。曾任國立臺灣大學財務金融學系系主任、教授、國立中央大學財務金融學系副教授、助理教授。曾獲科技部「吳大猷先生紀念獎」、「傑出研究獎」及國科會「甲種研究獎」等。研究專長為財務工程、資產配置、行為財務實證研究、風險管理、隨機利率模型；研究成果發表於Journal of Financial and Quantitative Analysis、Management Science、 Journal of Banking and Finance、Journal of Empirical Finance、Journal of Futures Markets、 Journal of Derivatives、Financial Analyst Journal、Quantitative Finance、Review of Derivatives Research、財務金融學刊、證劵市場發展季刊、經濟論文、管理學報、臺大管理論叢、期貨與選擇權學刊等學術期刊。近年研究興趣為: Black-Litterman模型的運用分析、機器人理財的績效評估、選擇權價量資訊的運用分析、機器學習和技術分析的選股策略研究、buy and hold策略的賣出時點研究等。',
        avatar:
            '/san_lin_chung.jpg',
    },
    // {
    //     name_ch: '王之彥',
    //     name_en: 'Jr-Yan Wang',
    //     title: '國立台灣大學 國際企業學系暨研究所  教授',
    //     email: 'jryanwang@ntu.edu.tw',
    //     expertise: "財務工程、隨機利率模型、信用風險、行為財務學",
    //     website: "http://homepage.ntu.edu.tw/~jryanwang",
    //     bio:
    //         '王之彥為國立臺灣大學資訊工程學士、碩士及國立臺灣大學國際企業學系財務博士，現任職國立臺灣大學國際企業學系教授，曾任國立臺灣大學國際企業學系副教授、助理教授、國立臺灣科技大學財務金融研究所助理教授、國立中興大學財務金融學系助理教授。曾獲臺灣大學管理學院玉山學術獎。研究專長為財務工程、信用風險模型、隨機利率模型、資產定價、行為財務學；研究成果發表於Management Science、 Journal of Banking and Finance、Journal of Futures Markets、 Journal of Derivatives、Review of Derivatives Research、Annals of Operations Research等學術期刊。近年研究興趣為: 風險態度與選擇權合理價格、選擇權隱含資訊、價量資訊的運用分析、高頻交易等。',
    //     avatar:
    //         '/jr-yan-wang.png',
    // },
    {
        name_ch: '莊文議',
        name_en: 'Wen-I Chuang',
        title: '國立臺灣大學財務金融學系暨研究所 副教授',
        email: 'wichuang@ntu.edu.tw',
        expertise: "行為財務學、證劵市場",
        website: "http://www.management.ntu.edu.tw/Fin/faculty/teacher/sn/69",
        bio:
            '',
        avatar:
            '/wen_i_chuang.png',
    },
]


const FINLAW_MEMBERS = [
    {
        name_ch: '林仁光',
        name_en: 'Jen-Guang Andrew Lin',
        title: '國立臺灣大學律學院 教授',
        email: 'andrewlin@ntu.edu.tw',
        expertise: "公司證券法、國際商事法、金融法",
        website: "http://www.law.ntu.edu.tw/index.php/認識本院/本院師資/item/234-林仁光",
        bio:
            '林仁光教授為美國波士頓大學國際銀行法法學碩士(LL.M. in International Banking Law Studies)、美國杜克大學法學碩士(LL.M.) 、法學博士(S.J.D.)。2003年起任職於國立臺灣大學，曾兼任國立臺灣大學企業暨金融法制研究中心主任、商事法中心主任；中華民國投信投顧公會理事；財團法人證券投資人及期貨交易人保護中心董事、調處委員；台灣法學會商事法委員會主任委員、財經法委員會主任委員；經濟部貿易調查委員會委員；NTU Law Review主編。主要研究領域包括：公司法、證券交易法、銀行法、金融法、英美契約法、比較企業金融法制、亞洲新興市場企業金融法制。',
        avatar:
            '/jen_guang_lin.jpg',
    },
    {
        name_ch: '汪信君',
        name_en: 'Hsin-Chun Wang',
        title: '國立臺灣大學律學院 教授',
        email: 'hcwang@ntu.edu.tw',
        expertise: "保險法、保險監理、金融市場管制、強制責任保險與侵權行為",
        website: "http://www.law.ntu.edu.tw/index.php/認識本院/本院師資/item/237-汪信君",
        bio:
            '汪信君教授於1994年自政治大學法律學系畢業後，於1996年取得政治大學商學碩士（保險研究所），1998年赴英攻讀法學博士學位，並於2002年取得倫敦大學法學博士（QMC/CCLS）。其代表著作為Reinsurance Regulation-A Contemporary and Comparative Study (Kluwer International 2002)，該著作並於2005年為美國風險與保險學會（ARIA）評選為2005年度保險專書獎(Kulp-Wright Book Award)。於2016年以E-Commerce and Distribution of Insurance Products一文，收錄由Springer出版The "Dematerialized" Insurance-Distance Selling and Cyber Risks from an International Perspective一書。其研究領域為保險法、保險監理、金融市場管制。目前研究方向主要在於保險業清償能力監理與系統風險、行為經濟學與保險商品管制、未成年人死亡保險等議題。',
        avatar:
            '/hsin_chun_wang.jpg',
    },
    {
        name_ch: '蔡英欣',
        name_en: 'Ying-Hsin Tsai',
        title: '國立臺灣大學法律學院 教授',
        email: 'ytsai@ntu.edu.tw',
        expertise: "公司法、海商法、電子支付法、國際商事法、金融法",
        website: "http://www.law.ntu.edu.tw/index.php/認識本院/本院師資/item/208-蔡英欣",
        bio:
            '蔡英欣教授為國立臺灣大學法學士、法學碩士，日本東京大學法學碩士、法學博士。曾任執業律師。研究領域為商事法，其中尤以公司法、海商法、電子支付法、國際商事法以及金融法等為主要研究對象。教授科目有公司法、海商法、企業法制專題研究、電子支付法專題研究、比較海商法專題研究、日文法學名著、商事法等課程。',
        avatar:
            '/ying_hsin_tsai.jpg',
    },
    {
        name_ch: '楊岳平',
        name_en: 'Yueh-Ping (Alex) Yang',
        title: '國立臺灣大學法律學院 副教授',
        email: 'alexypyang@ntu.edu.tw',
        expertise: "比較公司治理、金融監理、資本市場法制、國際經貿法、法律經濟分析",
        website: "http://www.law.ntu.edu.tw/index.php/認識本院/本院師資/item/1502-楊岳平",
        bio:
            '楊岳平教授於2005年自台灣大學法律學系取得法學士後，於2010年取得台灣大學法學碩士，其間並在眾達國際法律事務所執業。2011年獲教育部公費赴美國哈佛大學法學院攻讀法學碩士(LL.M)與法學博士(S.J.D.)，於2017年取得哈佛大學法學博士學位。目前研究興趣主要在金融監理與公司治理，包括金融風險管理、金融科技、消費金融、多層次金融機構、資本市場、國際金融等等。',
        avatar:
            '/yueh_ping_yang.jpg',
    },
]

const INDUSTRY_MEMBERS = [
    {
        name_ch: '陳永耀',
        name_en: 'Yung-Yaw Chen',
        title: '國立臺灣大學電機工程學系 教授',
        email: 'yychen@ntu.edu.tw',
        expertise: "智慧型控制、模糊邏輯、精密伺服控制、超音波加熱治療、居家看護",
        website: "https://ee.ntu.edu.tw/article/teacher.person/jobSN/1/navSN/356/webSN/376/teacherSN/78",
        bio:
            'Yung-Yaw Chen (陳永耀) received the B.S. degree in electrical engineering from National Taiwan University in 1981 and the Ph.D. degree in electrical engineering and computer sciences from University of California at Berkeley in 1989. He is currently a professor of the department of electrical engineering, National Taiwan University, Taipei, Taiwan, where he does research on intelligent control, fuzzy logic, computational intelligence, precision servo control, hyperthermia treatment planning, and augmented reality mini-invasive surgical system. He has published over 130 papers, including about 40 journal papers in these areas. He received the Excellent Research Awards from National Science Council in 1990 and 1991. He acted as the Program Chair in 1996 Asian Fuzzy Systems Symposium and Vice Program Chair in 2000 IFSA conference and also served as an associate editor in International Journal of Fuzzy Systems. He is a member of the IEEE Control Systems Society, Computer Society, Neural Networks Society, Systems, Man, and Cybernetics Society, and Ultrasound society.',
        avatar:
            '/yung_yaw_chen.png',
    },
    {
        name_ch: '張智星',
        name_en: 'Jyh-Shing Roger Jang',
        title: '臺灣大學資訊工程學系/資訊網路與多媒體研究所 教授、玉山金控 科技長',
        email: 'jang@csie.ntu.edu.tw',
        expertise: "精準行銷/搜尋/推薦、金融科技之AI應用、醫療大數據分析、音樂分析/檢索、語音辨識/評分、多媒體分析/檢索",
        website: "http://mirlab.org/jang",
        bio:
            '張智星教授於 1992 年取得加州大學柏克萊分校的電機電腦博士，1993 年的 ANFIS 論文在 Google Scholar 被引用次數超過一萬次。張教授於 1993 年加入美國 MathWorks 公司，開發與 MATLAB 共用的模糊邏輯工具箱。1995 年回台任教後，專注於機器學習的各項應用，包含語音、音樂、文件、影像辨識等領域。他曾經擔任 2014 及 2017 年 ISMIR 的大會主席及共同主席，他的團隊也在歷年 MIREX 國際音樂檢索評比中的數個項目拿下第一名的佳績。目前張教授執行多項與金融科技相關之計畫，包含精準行銷、搜尋與推薦，並擔任玉山金控科技長，以及工研院資通所的顧問。',
        avatar:
            '/jyh_shing_jang.jpg',
    },
    {
        name_ch: '王鈺強',
        name_en: 'Yu-Chiang Frank Wang',
        title: '國立臺灣大學電機工程學系 教授',
        email: 'ycwang@ntu.edu.tw',
        expertise: "電腦視覺、機器學習、人工智慧",
        website: "http://cvlab.ee.ntu.edu.tw",
        bio:
            "Yu-Chiang Frank Wang received his B.S. degree in Electrical Engineering from National Taiwan University in 2001. He received his M.S. and Ph.D. degrees in Electrical and Computer Engineering from Carnegie Mellon University in 2004 and 2009, respectively. In 2009, Dr. Wang joined the Research Center for Information Technology Innovation (CITI), Academia Sinica as an Assistant Research Fellow, and later he was promoted as the Associate Research Fellow in 2013, and also served as the Deputy Director of CITI from 2015 to 2017. In 2017, Dr. Wang joins the Graduate Institute of Communication Engineering and Department of Electrical Engineering at National Taiwan Univeristy as an Associate Professor. With continuing research focuses on computer vision and machine learning, Dr. Wang's recent research topics include deep learning for transfer learning, domain adaptation, face recognition, semantic segmentation, and multi-label classification. Dr. Wang serves as organizing committee members and area chairs of multiple international conferences such as CVPR, ICCV, ECCV, and ACCV. Several of his papers are nominated for the best paper awards, including IEEE ICIP, ICME, AVSS and MVA. Dr. Wang is twice selected as the Outstanding Young Researcher by the Ministry of Science and Technology of Taiwan (2013-2015 and 2017-2019).",
        avatar:
            '/yu_chiang_wang.jpg',
    },
]

const ASSISTANTS = [
    {
        name_ch: '方榕',
        name_en: 'Rong Fang',
        title: '秘書',
        email: 'nicolefang@ntu.edu.tw',
        phone: '(02) 3366-4888 #542',
        avatar: '/rong_fang.png'
    },
]



export { CHIEF_PREV, HEAD_MEMBERS, FINTECH_MEMBERS, FINBUSS_MEMBERS, FINLAW_MEMBERS, INDUSTRY_MEMBERS, ASSISTANTS };