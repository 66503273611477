const ACTIVITIES = [
    {
        date: "2023-06-14",
        title: "ChatGPT 在金融與企業之發展與應用論壇",
        intro: "ChatGPT 作為先進的自然語言處理技術，對金融科技與企業帶來了重大衝擊和廣泛應用，更加速了科技轉型的腳步。本論壇邀請 AI 領域、金融保險領域、AI 獨角獸企業、銀行業、國際級管理諮詢公司的專家學者進行演說與座談會交流，探討 ChatGPT 於現今金融科技和企業領域的潛在發展與創新應用，以及相應的影響、挑戰與未來的應對策略，期望匯聚各方專業人士分享經驗和見解，促進交流與合作，進而推動 AI 智慧發展應用。",
        place: "國立臺灣大學法律學院霖澤館",
        fileName: "0614金融中心論壇邀請函.jpg",
        pics: [
            "chatgpt論壇_pic1.jpg",
            "chatgpt論壇_pic2.jpg",
            "chatgpt論壇_pic3.jpg",
            "chatgpt論壇_pic4.jpg",
        ],
        paragraph: [
            "臺大金融科技研究中心與臺大國際產學聯盟於6月14日成功舉辦了以「ChatGPT在金融與企業之發展與應用」為主題的論壇活動。近兩百名專業人士參與了此次盛會，包括學者、業界專家和企業領袖，共同探討ChatGPT在金融和企業領域的潛能和挑戰。",
            "ChatGPT 自 2022 年底迅速崛起後引起了廣泛的關注和興趣，不僅對於金融科技和各企業領域具有重大意義，同時也在各行各業引發了革命性的影響。臺大金融科技研究中心主任陳祝嵩提到：「臺大金融中心舉辦本次論壇目的，就是希望趁著這波浪潮，集結專家學者各方觀點，帶來創新思考，促進ChatGPT原先基礎模型在企業界的應用，到了公司內部能再發展出專才的模型。」臺大研發長暨國際產學聯盟主任吳忠幟在開場致詞中指出：「AI 科技在過去十年有了驚人的發展，相對於其他 AI 技術的應用，ChatGPT 對一般使用者來說更加直接，感受也更為強烈。ChatGPT 創造了許多的機會，為使用者與企業提升了工作效率、加速數位轉型，但同時也帶來資安、倫理、法律等方面的考量與風險。新的科技需要新的因應，而這也正是我們籌畫本場論壇的起因。」",
            "論壇的前半場涵蓋了多個主題演講，臺大資訊工程學系陳縕儂副教授首先從近期生成式 AI與 過去的差異談起，分享了如何提升 ChatGPT 的使用經驗，以及如何預防例如資料外洩、資訊錯誤等技術困難。政治⼤學數位金融創新實驗室謝明華執行長則談及數位轉型對於組織的重要性，並分享了數個成功轉型的企業案例。接著，玉山金控張智星科技長探討了生成式 AI 目前在金融業的應用與面臨的痛點；沛星科技首席科學家林守德則從不同類型的企業角度，討論組織如何確認 AI 的應用範圍、導入流程以及成效評估。最後，麥肯錫亞洲資深顧問兼前花旗台灣商業銀行董事長管國霖則探討了 AI 如何從客戶的角度提供整合型、客製化的服務，並從經營者的角度優化成本效益、員工效能，進而成就以客戶為中心的智慧金融。",
            "此外，所有講者於演講後進行一場Panel Discussion座談會，由臺大金融科技研究中心陳祝嵩主任擔任主持人，與所有講者針對 ChatGPT 以及不同的 AI 技術在導入企業應用時的挑戰進行深入交流和討論，各講者分享了豐富的實務經驗和見解，並積極交換意見。本論壇成功展現了 AI 科技於金融與企業領域的無窮潛力，促進跨界合作與知識交流，同時也凸顯了科技研究與產業接軌的重要性。通過產學密切合作，臺灣大學期待讓本論壇的效益加乘，持續與學者、業界專家和企業領袖攜手合作，打造更多的創新應用和技術突破。",
            "主辦單位：臺大金融科技研究中心、臺大國際產學聯盟",
            "協辦單位：政大風險與保險研究中心",
        ],
    },
    {
        date: "2023-10-05", 
        date2: ["2023年10月5日（四）", "2023年9月26日（二）", "2023年9月27日（三）", "2023年10月3日（二）"],
        title: "2023玉山銀行校園商業競賽",
        name: ["【台灣大學場】", "【政治大學場】", "【成功大學場】", "【清華大學場】"],
        intro: "玉山銀行致力提供多元、便利的數位金融服務及平台，解決顧客金融大小事，並持續發掘顧客隱性需求，提供客製化的解決方案。期盼年輕族群透過競賽，讓創意與產業經驗接軌，共同打造新世代數位金融服務。",
        place: ["台灣大學 管理學院 管一104教室", "政治大學商學院玉山廳", "成功大學 資訊工程系館1樓4203階梯教室", "清華大學115教室（舊育成大樓）"],
        time: "12:00-14:00",
        fileName: "2023玉山銀行校園商業競賽海報.jpg",
        pics: [],
        paragraph: [
        ],
        link: ["https://forms.gle/Kib9UqkqzXXJJvBa7", "https://forms.gle/qvbEH6PcWKpXvnmu7", "https://forms.gle/A7wGeoit7CEaTnDp9", "https://forms.gle/Vp4vCMmUkYKDwGrW6"],
    },
    {
        date: "2024-07-15", 
        date2: ["7/15(一)", "7/16(二)", "7/19(五)", "7/22(一)", "7/24(三)", "7/26(五)"],
        title: "第9屆經濟部國家產業創新獎說明會",
        intro: "為鼓勵產、政、學、研界跨越領域藩籬，融入人文、服務、系統整合等元素，經濟部特設置國家產業創新獎，以國家級榮譽樹立產業學習典範，表揚產、政、學、研界投入「整合創新」創造附加價值之組織、團隊及個人菁英。",
        place: ["臺灣科學工業園區科學工業同業公會中科園區702會議室 <台中市大雅區中科路6號7樓>", 
            "臺大醫院國際會議中心402AB <台北市中正區徐州路2號4樓>",
            "高雄軟體科學園區401中型會議室 <高雄市前鎮區復興四路12號>",
            "嘉義產業創新研發中心服務大樓3樓小型會議室 <嘉義市西區博愛路二段569號服務大樓3樓>",
            "臺南文化創意產業園區金龍展演廳 <臺南市東區北門路二段16號>",
            "臺灣科學工業園區科學工業同業公會竹科園區202會議室 <新竹科學園區展業一路2號2樓>",
        ],
        area: ["臺中", "臺北(同步視訊)", "高雄", "嘉義", "臺南", "新竹"],
        time: ["14:00-16:00", "09:30-11:30", "14:00-16:00", "14:00-16:00", "14:00-16:00", "14:00-16:00"],
        fileName: ["第9屆經濟部國家產業創新獎簡章.pdf", "第9屆經濟部國家產業創新獎說明會報名表.docx"],
        pic: "第9屆經濟部國家產業創新獎圖片.png",
        QRcode: "第9屆經濟部國家產業創新獎QR code.jpg",
    }
]

export { ACTIVITIES }